import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './courosel.scss';
class BannerCarousel2 extends Component {
    render() {
        const getConfigurableProps = () => ({
            showArrows: false,
            infiniteLoop: true,
            showStatus: false,
            showThumbs: false,
            showIndicators: true,
            autoPlay: true,
            stopOnHover: true,
            interval: 2000,
            transitionTime: 400,
            swipeScrollTolerance: 2
        });
        return (
            <div className="courosel2Cnt">
                <Carousel {...getConfigurableProps()}>
                    <div>
                        <img src='./room10.jpg' />
                    </div>
                    <div>
                        <img src='./room8.jpg' />
                    </div>
                    <div>
                        <img src='./room1.jpg' />
                    </div>
                    <div>
                        <img src='./room2.jpg' />
                    </div>
                    <div>
                        <img src='./room3.jpg' />
                    </div>
                    <div>
                        <img src='./room4.jpg' />
                    </div>
                    <div>
                        <img src='./room5.jpg' />
                    </div>
                    <div>
                        <img src='./room6.jpg' />
                    </div>
                    <div>
                        <img src='./room7.jpg' />
                    </div>                    
                    <div>
                        <img src='./room9.jpg' />
                    </div>
                    <div>
                        <img src='./room11.jpg' />
                    </div>
                    <div>
                        <img src='./room12.jpg' />
                    </div>
                    <div>
                        <img src='./room13.jpg' />
                    </div>
                </Carousel>
            </div>
        );
    }
}

export default BannerCarousel2
