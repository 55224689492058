import React from 'react';
import './footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Footer() {
  return (
    <div className="footer">
      <div className="footerCnt">        
        <div className="taggingCnt">
          <ul>
            <li>Hotels in Thirunallar</li>
            <li>Navagraha temple info</li>
            <li>Lord siva temple in chola nadu</li>
          </ul>
          <ul>
            <li>Hotels in Karaikkal</li>
            <li>Room in Thirunallar</li>
            <li>Temples around kumbakonam</li>
          </ul>
          <ul>
            <li>Thirunallar Hotel</li>
            <li>Thirunallar Room</li>
            <li>Book a room in Thirunallar</li>
          </ul>
          <ul>
            <li>Hotels in Thirunallar</li>
            <li>Rooms in Thirunallar</li>
            <li>Stay in Thirunallar</li>
          </ul>
          <ul>
            <li>Thirunallar Hotel</li>
            <li>Thirunallar Room</li>
            <li>Navagraha travel tips</li>
          </ul>
        </div>
        <div className="divineTour"><a href="http://www.navagraham.com/" target="_blank"><img src="./navagrahamLogo.png" /></a></div>
        <p className="copyright">Copyright © 2021 www.senthilpalace.com | Powered by : RS Websolutions</p>
      </div>
    </div>
  )
}
export default Footer;