import React, { Component } from 'react';
import './covidcare.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
class Covidcare extends Component {
    constructor() {
        super();
        this.state = {
            headerState: false
        }
    }
    render() {
        return (
            <div className="covidBlk">
                <p className="titleTxt">Travel Information: COVID-19 Update-Caring for You- Safety Measures For Our Guests</p>
                <ul>
                    <li>Wear a mask. my mask protects you, your mask protects me.</li>
                    <li>The wellbeing of our guests and associates is important to us.</li>
                    <li>We are constantly updating COVID - 19 guidelines from World Health Organization and the local health departments</li>
                    <li>We are also coordinating very closely with all local government health authorities.</li>
                    <li>As abundant precaution we are training our associates on CoV-19 prevention and surveillance.</li>
                    <li>We are taking zero compromise hygiene and safety measures.</li>
                    <li>As a group we are taking proper steps to sanitize all our staff and guest contact areas such as rooms, restaurants, kitchens, public space, associate facilities etc.</li>
                    <li>We care for your safety.</li>
                </ul>
            </div>
        )
    }
}
export default Covidcare;