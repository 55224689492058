import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faWifi, faDesktop, faTrain, faBath, faShoppingBag, faShoppingCart, faCarAlt } from '@fortawesome/free-solid-svg-icons';
import './home.scss';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import BannerCarousel2 from '../../components/courosel2/courosel2';
import Navatemples from '../../components/navatemples/navatemples';
import Covidcare from '../../components/covidcare/covidcare';
const config = require('../../config');
class Home extends Component {
  constructor() {
    super()
    this.state = {
      popup: false,
      username: '',
      phone: '',
      email: '',
      room: '',
      adult: '',
      children: '',
      startDate: '',
      endDate: '',
      msg: ''
    }
  }
  sendmail = () => {
    let sObj = JSON.stringify({
      "userName": this.state.username,
      "userPhone": this.state.phone,
      "userEmail": this.state.email,
      "roomType": this.state.room,
      "Adult": this.state.adult,
      "children": this.state.children,
      "totalPersons": Number(this.state.adult) + Number(this.state.children),
      "bookingDate": this.state.startDate + ' to ' + this.state.endDate,
      "userMsg": this.state.msg
    });
    const requestOptions = {
      method: 'POST',
      body: sObj
    };
    this.setState({ popup: true });
    fetch(config['baseurl'] + 'sendmail.php', requestOptions);
    this.setState({
      username: '',
      phone: '',
      email: '',
      room: '',
      adult: 0,
      children: 0,
      startDate: '',
      endDate: '',
      msg: ''
    });
  }
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }
  showAbout() {
    document.getElementById('aboutCnt').scrollIntoView({ behavior: "smooth", block: "nearest" })
  }
  closePopUp = () => {
    this.setState({
      popup: false
    });
  }
  render() {
    return (
      <div className="container">
        <Header />
        <div className="home">
          <span className="bookingStrip glow" onClick={() => this.showAbout()}>Book a stay</span>
          <span className="whatsappStrip"><a href="https://wa.me/919894078988/?text=Hi%20Senthil%20Palace!%20I%20need%20room(s)%20for%20sale" target="_blank"><FontAwesomeIcon className="phoneIcon" icon={faWhatsapp} />&nbsp;Whatsapp us</a></span>
          <div className="bannerImg"><img src='./slider_1.jpg' /></div>
          <div className="sliderText">Senthil Palace renovated luxury hotel is situated very near to NALATHEERTHAM</div>
          <Covidcare />
          <div className="seprator">
            <div className="sepratorLine" />
            <img src='./home1.png' />
            <div className="sepratorLine" />
          </div>
          <p className="welcomeTitle">Welcome To</p>
          <p className="welcomeTitle1">HOTEL SENTHIL PALACE</p>
          <div className="tariffBlk">
            <div className="tariffCnt">
              <p className="bookingTitle">Tariff:</p>
              <div className="tariffs">
                <div className="tariff">
                  <p className="tariffName">Executive family suit</p>
                  <div className="rate">
                    <p>Weekend: ₹2975</p>
                    <p>Weekdays: ₹2475</p>
                  </div>
                </div>
                <div className="tariff">
                  <p className="tariffName">Deluxe family suit</p>
                  <div className="rate">
                    <p>Weekend: ₹2475</p>
                    <p>Weekdays: ₹1975</p>
                  </div>
                </div>
                <div className="tariff">
                  <p className="tariffName">Standard duble cot room</p>
                  <div className="rate">
                    <p>Weekend: ₹1475</p>
                    <p>Weekdays: ₹975</p>
                  </div>
                </div>
                <div className="tariff">
                  <p className="tariffName">Deluxe family room</p>
                  <div className="rate">
                    <p>Weekend: ₹1975</p>
                    <p>Weekdays: ₹1475</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactBlk">
              <p className="bookingTitle">For instant bookings call:</p>
              <p>04368 - 236333, 9894078988, 9445043322</p>
              <p>9445143322, 9445943322</p>
              <p>senthilpalace@gmail.com</p>
            </div>
          </div>
          <div className="abtCnt">
            <div>
              <p className="bookingTitle"><b>Book A Stay:</b></p>
              <div className="bookingForm" id="aboutCnt">
                <div className="msgForm">
                  <div>
                    <span className="formLabel">Name</span>
                    <div className="formField"><input type="text" name="username" value={this.state.username} onChange={this.myChangeHandler}></input></div>
                  </div>
                  <div>
                    <span className="formLabel">Phone</span>
                    <div className="formField">
                      <input type="tel" name="phone" value={this.state.phone} onChange={this.myChangeHandler}></input>
                    </div>
                  </div>
                  <div>
                    <span className="formLabel">Room Types</span>
                    <div className="formField">
                      <select name="room" placeholder="Select Your Room Type" value={this.state.room} onChange={this.myChangeHandler}>
                        <option value="">Select Your Room Type</option>
                        <option value="Double bed room AC">Double bed room AC</option>
                        <option value="Triple bed room AC">Triple bed room AC</option>
                        <option value="Family suite AC">Family suite AC</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <span className="formLabel">Email</span>
                    <div className="formField">
                      <input type="email" name="email" value={this.state.email} onChange={this.myChangeHandler}></input>
                    </div>
                  </div>
                  <div className="personCount">
                    <span className="formLabel">No.of Persons</span>
                    <div className="formField">
                      <input placeholder="No.of Adults" name="adult" type="number" value={this.state.adult} onChange={this.myChangeHandler}></input>
                      <input type="number" name="children" placeholder="No. of Children" value={this.state.children} onChange={this.myChangeHandler}></input>
                    </div>
                  </div>
                  <div className="personCount">
                    <span className="formLabel">Booking date</span>
                    <div className="formField">
                      <span>From:</span>
                      <input type="date" name="startDate" value={this.state.startDate} onChange={this.myChangeHandler}></input>
                      <span>To:</span>
                      <input type="date" name="endDate" value={this.state.endDate} onChange={this.myChangeHandler}></input>
                    </div>
                  </div>
                  <div>
                    <span className="formLabel">Message</span>
                    <div className="formField">
                      <textarea type="text" name="msg" value={this.state.msg} onChange={this.myChangeHandler}></textarea>
                    </div>
                  </div>
                  <p className="contactusBtnCnt">
                    <span className="btn btnPrimary" onClick={this.sendmail}>Submit</span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="facilities card">
                <p className="facilitiesTitle"><b>Facilities:</b></p>
                <ul>
                  <li><div><span className="liIcon"><img className="acIcon" src='./ac.svg' /></span><span>A/C and non A/C rooms</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faDesktop} /></span><span>T.V with multichannels</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faWifi} /></span><span>Wifi</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faBath} /></span><span>24 hours hot and cold water supply</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faTrain} /></span><span>Travel assistance</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faCarAlt} /></span><span>Spacious car parking</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faCcVisa} /></span><span>Debit cards / Credit cards accepted</span></div></li>
                  <li><div><span className="liIcon"><img src='./temple.svg' /></span><span>Very near to temple</span></div></li>
                  {/* <li><div><span className="liIcon"><img src='./pond.svg' /></span><span>Very near to NALATHEERTHAM</span></div></li> */}
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faShoppingBag} /></span><span>Pooja shop &amp; services</span></div></li>
                  <li><div><span className="liIcon"><FontAwesomeIcon icon={faShoppingCart} /></span><span>Readymade shop</span></div></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="seprator">
            <div className="sepratorLine" />
            <img src='./home1.png' />
            <div className="sepratorLine" />
          </div>
          <div className="roomsCnt">
            <div className="roomsWrap">
              <p className="roomsTitle"><b>Gallery:</b></p>
              <BannerCarousel2 />
            </div>
            <div className="about">
              <p className="aboutTitle"><b>About Senthil Palace:</b></p>
              <p>Senthil Palace a medium luxury hotel is situated very near to NALATHEERTHAM. Our hotel consists of deluxe and non-deluxe rooms from single bed occupancy to six bed occupancy. Our hotel is well maintained, luxury affordable, with ample carparking. We also arrange special poojas, travel assistance and Navagraha tour.</p>
              <p>Thirunallar is a small town 5 kms from Karaikal, India, in the Union Territory of Puducherry, and can be reached by road from the town of Karaikal, an enclave which lies within the neighbouring state of Tamil Nadu. Thirunallar is most noted for the shrine of Lord Sani (Saturn), Tirunallar Saniswaran Temple within the temple dedicated to Lord Darbharanyeswaran, a form of Lord Shiva.</p>
            </div>
          </div>
          <p className="locateTitle"><b>Locate us:</b></p>
          <div className="loactionCnt">
            <div className="map">
              <iframe src="https://www.google.com/maps/d/embed?mid=1LV99oJKQ6Tt3oZXVI-MBJJJsde9AIMWO" width="100%" height="350px"></iframe>
            </div>
            {/* <div className="mapImg">
              <img src='./senthil_palce_root_map.gif' />
            </div> */}
          </div>
          <div className={this.state.popup ? "popup" : "popupHidden"}>
            <div className={this.state.popup ? "messageBox" : "hidden"}>
              <p>Thank you, your enquiry has been submitted successfully</p>
              <span className="btn btnPrimary" onClick={this.closePopUp}>Okay</span>
            </div>
          </div>
        </div>
        <Navatemples />
        <Footer />
      </div>
    );
  }
}

export default Home;
