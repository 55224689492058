import React, { Component } from 'react';
import './navatemples.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
class Navatemples extends Component {
    constructor() {
        super();
        this.state = {
            'sun': false,
            'moon': false,
            'mars': false,
            'mercury': false,
            'jupiter': false,
            'venus': false,
            'sanee': false,
            'kethu': false,
            'rahu': false
        }
    }
    showNava(param) {
        this.setState({
            [param]: !this.state[param]
        })
    }
    render() {
        return (
            <div className="navaBlk">
                <div className="seprator">
                    <div className="sepratorLine"></div>
                    <p className="sliderText">NAVAGRAHAM TEMPLES</p>
                    <div className="sepratorLine"></div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('sun')}>
                        <div>SURIYANAR KOVIL (SUN)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.sun ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/sun.jpg" />
                        <div>This temple is close to Aduthurai and 15 km from Kumbakonam and also a kali Amman Temple that should be visited. Among the planets, Sun occupies the prime position. That is why the first day of the week is named Sunday. The seven planets including Saturn form the seven days of the week.The eight planet is called Rahu. is allotted 10 1/2 hours in seven days of the week. On each day 1-1/2 hours is allotted for Rahu. This one and a half hour is known as Rahu Kalam. The ninth planet is known as Ketu. For Ketu also the same is allotted. This one and a half hour is known as Yama HandamBoth Rahu's Time and Kato's Time are considered as inauspicious time. Suriyanar (Sun) Temple towers 15.5 meter high. It has three tiers. At the top of the tower there are five kalasam. Outside the Rajagopuram (main tower) in the north side there is a holy tank called Surya Pushkarni where one should take a bath, and those who are not in a position to bath should sprinkle water on their head. As you enter the Temple on the right side there is Theertha Vinayakar (Ganesh) Sannidhi is there and in the northern corridor one will find Thejas Chandikeswarar Sannidhi.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('moon')}>
                        <div>THINGALOOR (MOON)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.moon ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/moon.jpg" />
                        <div>Thingaloor is one of the Saivite holy places in Tamil Nadu. Thingal means Moon (Also Known as Chandran). At this temple only the southern entrance is used. On the left of the temple Lord Kailashnath and in the opposite side Goddess Periyanayaki. Around the outer corridor we can have darisanam of Lord Dhakshinamoorty and in the eastern side is Chandra Baghawan (Moon) is one of the benevolent planets among the nine planets. Lord Shiva has three eyes and Moon is considered the left eye of the Lord. Moon is known for her soft nature and is an integral part of Goddess Parasakhi. Monday is the main day to prayer for her. The adverse effect of Moon manifests them in loss of mental peace. Ill health, possibility loss of life in water, fear etc. By worshiping Moon we can get relief from these adverse effect. Those who worship Moon are blessed with her favourable effect such as comfortable wealth, good health, Mother's welfare and auspicious events in the whole family of devotees.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('mars')}>
                        <div>VAITHEESWARAN KOVIL (MARS)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.mars ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/mars.jpg" />
                        <div>Mars at Vaitheeswaran Kovil. Mars faces the south, and Lord Dhandayuthapani who is in the east facing Mars. Mars showers on his devotee's benefits such as good harvest in the fields, good income and save his devotees from sufferings. He enhances the respectability and dignity of those who worship him. He also helps his devotees to come out of financial indebtedness. Mars is worshiped on Tuesdays by offering red flowers.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('mercury')}>
                        <div>THIRUVENKADU (BUDHAN - MERCURY)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.mercury ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/mercury.jpg" />
                        <div>Among the nine planets, Budhan Baghawan worshipped in Thiruvenkadu Temple will give bliss. Mercury protects Shiva Bakthas and literary persons. He is green in complexion, soft in nature. He bestows Lord Shiva devotees with the knowledge in Astrology, Sculpture, and Medical treatment of paralysis. He also grants power of expression and success in education, literary pursuits, fame in fine arts, and profession and good relations with scholars, Wednesday is the day to worship him offeriong green colour items and he likes white kanthal. Evil effects by Mercury are usually failure in business, set back in profession, lack of mental calibre, and transfer from native place, getting caught in quarrels.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('jupiter')}>
                        <div>ALANGUDI (JUPITER)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.jupiter ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/jupiter.jpg" />
                        <div>In Alangudi, Guru is worshiped as Sri Dhakshinamurthy. Lord Shiva as Abath Sahayar and Goddess Parvathi known as Aelavarkuzhali, are the main deities here. When one visit Guru they should offer 24 ghee or oil lamps which can be got at the temple and after offering the lamps one should walk 24 times around him. He is the Guru of the Devas, and with Guru's grace, even an illiterate person can become a great scholar. The good effect of Guru came in the form of success in life, prosperity and good events like marriages. Guru is the prime benevolent planet among Nine Planets. Thursday is the preferred day for worshipping Guru Baghawan. Wearing yellow clothes, giving away yellow clothes as charity, observing fast on Thursdays are the proper way of worshipping Guru, for propitiation and getting relief from adverse effect of Guru, such as illness and financial difficulty. The choicest flower fro Guru is White Jasmine.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('venus')}>
                        <div>KANCHANOOR (VENUS - SUKRAN)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.venus ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/venus.jpg" />
                        <div>Kanjanoor is the holy abode from Sukra Baghawan. Kananoor is in Thanjavur Diustrict, which is two km. away from Suriyanar Kovil near Aduthurai. Those who worship Venus will be bestowed with happy marriage and prosperity. The main deity here is Sri Agnipuriswarar. Venus showers on her devotees many blessings such as health, happiness, comfortable life and wealth. The ill effect of Sukra Dasa may lead to betrayal by women. One may suffer from venereal disease. By worshiping Venus, one can obtain propitiation from Sukra Dosha (adverse effect) To get rid of evil effect in ones life Venus has to worship on Fridays. She loves to be decorated with white cloths. Those who have a weak Venus in their chart or born on the 6 or any number that add up to 6 should wear a diamond ring. Aum jung, hang sa bhur bhuvan swa kartike namah swa bhuvan bhur sa hangs jung aum.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('sanee')}>
                        <div>THIRUNALLARU (SANEESWARAR)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.sanee ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/saneeswaran.jpg" />
                        <div>Saturn temple, Sri Dharparanyeshwarar's temple is in Thirunallaru, 45 km from Kumbakonam. It is one of the seven famous Vidangar Temples and is well known and famous for Sri Saneeswara Baghawan for whom a sperarate sanctum is provided. As you enter the northen side Gopuram,in the righ side t Sani Baghawan's Sannidhi is there, and faces the east. In the temple corridor, we will come across the idol of 63 Saivite Saints (Nayanmars), Siva Lingam,Ambal,Sri Dhakshinamurthy Swani,Swarna Vinayakar, Skandar,Saptha Vidanga Shiva Lingams, Subramanya Swamy accompanied by his consorts valli and Daivayanai, Naka Narayanar, Durgai, Chandikesar, Bairavar, Natarajar, Suryadevar and others. This temple has many holy tanks and one of it is Nala theertham that is considered most sacreds. King Nala took bath in this Tank and got rid of adverse and evil effect. A bath or sprinkling the water from the tank over the head with total devotion will help to get rid of adverse inflictions of evils will vanish and so will sorrow. Saturdays are the most appropriate days for worshipping Sri Sani Baghawan.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('kethu')}>
                        <div>KEELA PERUMPALLAM (KETHU)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.kethu ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/kethu.jpg" />
                        <div>He is located at Keelaperumpallam about 20 Km from Srikali Here, there is a Temple for Naganatha Swamy. In the temple there is a separate sannidhi for Ketu. Here Ketu is facing west. Evil effect of Ketu will create obstacles in one's endeavours, enemy and ill health. Good effect of Ketu will improve financial position, good health, mental peace and happiness. Worshipping and fasting on Tuesday will relieve the ill effect to a great extend.Worship of Ketu is best done on Tuesday. Fasting on this day will help; you should take sweet potatoes and fruit juice once a day and avoid salt and spices.</div>
                    </div>
                </div>
                <div className="dropStrip">
                    <div className="dispCnt" onClick={() => this.showNava('rahu')}>
                        <div>THIRUNAGESWARAM TEMPLE (RAHU)</div>
                        <div><FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon></div>
                    </div>
                    <div className={this.state.rahu ? "detailedCnt" : "hidden"}>
                        <img src="../../nava/kethu.jpg" />
                        <div>Tirunageswaram, This vast temple known for its shrine to Rahu, one of the nine celestial bodies in the Navagraha situated 8kms from Paradise Resort. A legend has it that the mythological serpents Adiseshan, Dakshan and Kaarkotakan worshipped Shiva here. Legend also has it that King Nala worshipped Shiva here as in Thirunallar. Rahu is instrumental in strengthening one's power and converting even an enemy into a friend. He is ruled by Goddess Durga. The main deity's name is Nageshwarar and Ambal's name is Girigujambikai. She is decorated as a small girl in the Mornings, as young lady in Noons and as lady in evenings.. Here Ragu resides with his wife and everyday, during Rahu kalam, when Milk is poured over Rahu idol, it miraculously changes to blue colour. People having Ragu dosha come here during Rahu kalam and perform Abishekas.</div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Navatemples;