import React, { Component } from 'react';
import './header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faBars } from '@fortawesome/free-solid-svg-icons';
class Header extends Component {
    constructor() {
        super();
        this.state = {
            headerState: false
        }
    }
    showHeader(){
        if(this.state.headerState){
            this.setState({
                headerState: false
            })
        } else {
            this.setState({
                headerState: true
            })
        }
    }
    render() {
        return (
            <div className="header">
                <div className="headName">
                    <img src={'./icon.png'} />
                    <div className="headText">
                        <p>SENTHIL PALACE</p>
                        <p>Thirunallar, Karaikal, Pondicherry</p>
                    </div>
                    <div className="headIcon">
                        <FontAwesomeIcon onClick={()=>this.showHeader()} icon={faBars}></FontAwesomeIcon>
                    </div>
                </div>

                <div className={this.state.headerState ? "headAddressSm" : "headAddress" }>
                    <p>No.1 Pudhu Road, Nalatheertham, Thirunallar-609607</p>
                    <p>senthilpalace@gmail.com</p>
                    <p>04368 - 236333, 9894078988, 9445043322</p>
                    <p>9445143322, 9445943322</p>
                    <p className="visitor">Visitor's Count&nbsp;:&nbsp;
                    <a href="https://www.freecounterstat.com/geozoom.php?c=3db09b678ba3a57d408750605a33cff2&base=counter6&type_clic=1" title="visitors counter" target="_blank">
                            <img src="https://counter8.stat.ovh/private/freecounterstat.php?c=zkmwn8jupgfe8u815bzw6zp9g6by64k6" border="0" title="view counter" alt="website view counter" />
                        </a>
                    </p>
                </div>
            </div>
        )
    }
}
export default Header;
